.description {
	font-size: 14px;
	margin-bottom: 0rem;
}

.mainContainer {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	margin-bottom: 3rem;
	margin-top: 1.5rem;
}

.flexRow {
	display: flex;
	gap: 0.3rem;
	flex-wrap: wrap;
	flex-direction: row;
}
.list {
	display: flex;
	flex-direction: row;
	padding: 0rem;
	gap: 1rem;
	flex-wrap: wrap;
	margin-bottom: 0rem;
}

.subTitle {
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 0rem;
	font-family: 'Editorial';
}

.descMain {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.valueDim {
	display: flex;
	flex-direction: row;
	column-gap: 0.225rem;
}

.height {
	width: 3px;
	height: 100%;
	border-radius: 3px;

	background-color: var(--text-main);
}
.width {
	width: 100%;
	height: 3px;
	border-radius: 3px;

	background-color: var(--text-main);
}

.depth {
	width: 3px;
	height: 100%;
	border-radius: 3px;
	background-color: var(--text-main);
	transform: rotate(45deg);
}

.dimensionsBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
}

.dimIcon {
	width: 100%;
	height: 100%;
}

.dimensionsRow {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
}

.dimensionsCol {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.dimensionsItem {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1rem;
	font-size: 14px;
	width: 100%;
	list-style: none;
	margin: 0rem;
	padding: 0;
}
.listItem {
	display: flex;
	flex-direction: column;
	gap: 5px;
	font-size: 14px;
	width: 48%;
	list-style: none;
	margin: 0rem;

	padding: 0;
}

.listItem1 {
	display: flex;
	flex-direction: column;
	gap: 5px;
	font-size: 14px;
	width: 48%;
	list-style: none;
	margin: 0rem;

	padding: 0;
}

.infoIcon {
	width: 13px;
	height: 13px;
	color: var(--text-body);
}

.key {
	display: flex;
	align-items: center;
	column-gap: 0.25rem;
	font-size: 14px;
	font-weight: 500;
	color: var(--text-main);
	margin-bottom: 0rem;
	text-transform: uppercase;
}
.keyMain {
	display: flex;
	align-items: center;
	column-gap: 0.25rem;
	font-size: 16px;
	margin-bottom: 0rem;
	font-weight: 500;
	color: var(--text-main);
	text-transform: uppercase;
}

.colorRound {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: var(--primary-bg);
}
.tabContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.value {
	margin-bottom: 0rem;
	display: flex;
	align-items: center;
	font-weight: 300;
	text-transform: capitalize;
}

.value1 {
	/* margin-bottom: 0rem;
  display: flex;
  align-items: center;
  font-weight: 300;
  text-transform: capitalize;
  cursor: pointer; */
	white-space: nowrap;
	background-color: var(--primary-bg);
	border-radius: 999999px;
	padding: 0.25rem 0.5rem;
	font-size: 14px;
	line-height: 1em;
	list-style: none;
	color: var(--text-main);
	text-transform: capitalize;
	cursor: pointer;
	width: fit-content;
	margin-bottom: 0rem;
	transition: all 0.2s ease-in-out;
}

.colorFilterBox {
	/* background-color: var(--primary-bg); */
	border: 1px solid var(--primary-border);
	border-radius: 999999px;
	padding: 0.25rem 0.5rem;
	font-size: 14px;
	line-height: 1em;
	list-style: none;
	color: var(--text-main);
	text-transform: capitalize;
	cursor: pointer;
	width: fit-content;
	margin-bottom: 0rem;

	display: flex;
	flex-direction: row;
	gap: 0.5rem;
}

.value1:hover {
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	transition: all 0.2s ease-in-out;
}

.hideExtra {
	overflow: hidden;
	font-size: 14px;
	display: -webkit-box;
	-webkit-line-clamp: 4; /* number of lines to show */
	-webkit-box-orient: vertical;
	color: var(--text-main);
}

.showAll {
	font-size: 14px;
	color: var(--text-main);
}

.readMore {
	padding: 0rem;
	font-size: 14px;
	color: var(--text-body);
	text-decoration: underline;
	text-align: left;
}

.readMore:hover {
	cursor: pointer;
	color: var(--text-main);
	text-decoration: underline;
}

@media (max-width: 600px) {
	.listItem {
		width: calc(48% - 1rem);
	}

	.listItem1 {
		width: calc(100%);
	}

	.mainContainer {
		border-top: 1px solid #eeeeee;
		padding-top: 1rem;
		margin-top: 0rem;
		margin-bottom: 1rem;
		gap: 1rem;
	}

	.hideExtra {
		font-size: 14px;
		font-weight: 300;
	}

	.showAll {
		font-size: 14px;
		font-weight: 300;
	}

	.value {
		font-size: 14px;
	}

	.value1 {
		font-size: 14px;
	}

	.dimensionsItem {
		flex-wrap: wrap;
	}
}
