.rowGap {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
}

.imageSection {
	display: flex;
	flex-direction: row;
	margin-right: 0.5rem;
	/* min-width: calc(50px - 0.5rem); */
}

.favoriteImg {
	width: 28px !important;
	border-radius: 50px;
	height: 28px !important;
	object-fit: cover;
	border: 2px solid white;
	margin-right: -12px; /* Adjusts the overlap. Negative value causes overlap */
}

.favoriteImgPlaceHolder {
	width: 28px !important;
	border-radius: 50px;
	height: 28px !important;
	object-fit: cover;
	border: 2px solid white;
	margin-right: -12px; /* Adjusts the overlap. Negative value causes overlap */
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
}

.presentInCartsBody {
	margin-bottom: 0rem;
	line-height: 1.2rem;
	font-size: 14px;
	max-width: 400px;
	color: var(--text-body);
}

.colorText {
	color: var(--primary-purple);
	font-weight: 500;
}

.presentInCarts {
	margin-bottom: 0rem;

	line-height: 1.2rem;
	font-size: 14px;
	max-width: 400px;
	font-weight: 400;
	color: var(--text-body);
}
