.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  background: var(--background);
  border: 1px solid var(--primary-border);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.tableHeader {
  background: var(--primary-bg-light);
  color: var(--primary-text);
  text-align: left;
  padding: 12px;
  font-weight: bold;
}
.linkToRetailer {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  text-decoration: none;
  color: var(--primary-text);
  font-weight: 400;
}

.retailerImage {
  background-color: white;
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  object-fit: contain;
  border-radius: 100%;
}

.linkToRetailer:hover .retailerName {
  text-decoration: underline;
}
.kashewBadge {
  background-color: var(--primary-pink);
  padding: 0.2rem 0.5rem;
  border-radius: 2px;
  font-size: 10px;
  opacity: 0.8;
  font-weight: 500;
  text-decoration: none !important;
}

.kashewBadge:hover {
  text-decoration: none !important;
}

.td {
  text-transform: match-parent;
  cursor: pointer;
  font-weight: 500;
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid var(--primary-border);
}

.tableRow:nth-child(even) {
  background: var(--primary-bg-light);
}

.tableRow:hover {
  background: var(--primary-bg);
}

.link {
  text-decoration: none;
  color: white;
}

.link:hover {
  text-decoration: underline;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.pageNumber {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-main);
}

.pageButton {
  border: 1px solid var(--primary-border);
  border-radius: 100%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;

  &:hover {
    background-color: var(--primary-bg-light);
  }
}

.chevron {
  width: 70%;
  min-width: 70%;
  height: 70%;
  min-height: 70%;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
