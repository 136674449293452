.wrapper {
  max-width: var(--max-width);
  margin: auto;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0 1rem;
  }
}
