.main {
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 2rem;
}

.containerPadded {
  display: flex;
  padding: 15px;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.tool {
  display: flex;
  flex-direction: row;
  margin: auto;
  font-size: 12px;
  width: 100%;
  color: black;
  padding: 10px 20px;
  background-color: var(--primary-bg);
  border-radius: 3px;
  align-items: center;
  margin-bottom: 0rem;
}

.toolActive {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: auto;
  font-size: 12px;
  color: white;
  padding: 10px 20px;
  background-color: var(--text-main);
  border-radius: 3px;
  align-items: center;
  margin-bottom: 0rem;
  transition: 0.2s linear;
}

.toolActive .icon {
  color: white !important;
}

.tool:hover {
  cursor: pointer;
  opacity: 0.7;
}

.toolActive:hover {
  cursor: pointer;
  opacity: 0.7;
}

.choice {
  display: flex;
  font-size: 14px;
  align-items: center;

  height: 25px;
}

.ImageContainer {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;

  /* border: solid rgba(184, 184, 184, 0.281) 1.5px; */
  border-radius: 1000%;
}

.profileInfo {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 8px;
  padding: 0.75rem 0.75rem;

  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
}

.profileText {
  color: var(--text-main);
  font-size: 14px;
  font-weight: 500;
}

.profileEmail {
  font-size: 12px;
  text-align: center;
  color: var(--text-mute);
}
.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 1000%;
}

.main {
  display: flex;
  height: calc(100vh - 75px);
  flex-direction: row;
}

.leftSection {
  width: 20%;
  position: fixed;
  height: inherit;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background-color: white;
  padding: 1em;
  min-width: 10em;
  border-right: 0.5px solid var(--primary-border);
}

.rightSection {
  width: 80%;
  height: inherit;
  min-height: 100%;
  height: calc(100vh - 75px);
  margin-left: auto;

  overflow-y: scroll;
}
.icon {
  margin-right: 0.5rem;
}

.icon2 {
  height: 45px;
  width: 45px;
  margin-top: auto;
  margin-bottom: auto;
  /* margin-right: 1em; */
}

.container {
  width: 70%;
  margin: auto;
  margin-top: 3em;
  font-size: 2em;
  text-align: center;
}

.dropFile {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--primary-border);
  height: 10em;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  padding: 2em;
  text-align: center;
}

.shopBox {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary-border);
  width: 100%;
  border-radius: 8px;
  padding: 1rem;
  row-gap: 0.5rem;
  cursor: pointer;
}

.shopBox:hover {
  background-color: var(--primary-bg);
}

.dragText {
  font-size: 14px;
  color: var(--text-body);
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal {
  text-align: center;
  font-size: 1.5em;
  /* color: var(--primary-purple); */
}

.commitButton {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 1em;
}

.success {
  border: solid rgba(149, 173, 204, 0.637) 3px;
  min-height: 10em;
  width: 40%;
  margin: auto;
  margin-top: 1em;
  border-radius: 10px;

  text-align: center;
  font-size: 2em;
  color: rgb(129, 149, 175);
}

.error {
  color: var(--primary-red-400);
  border-radius: 8px;
  font-size: 13px;
}

.backButton {
  margin: 2em;
  width: 80%;
}

.navButtonBack {
  display: none;
}

.backArrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  height: 56px;
  min-width: 56px;
  margin: 1em;
  cursor: pointer;
  width: 56px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.label {
  font-size: 14px;
  font-weight: 600;
}

.label1 {
  font-size: 14px;
  font-weight: 600;
}

.arrowBack {
  height: 20px;
  width: 20px;
}

.leftSection1 {
  display: none;
}

.selectCsv {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.optionIcon {
  margin: auto;
  vertical-align: top;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: 2em;
  width: 2em;
  color: var(--primary-purple);
}

.optionIcon2 {
  margin: auto;
  vertical-align: top;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: 1.5em;
  width: 1.5em;
  color: var(--primary-purple);
}

.optionContainer {
  margin: auto;

  background-color: white;
  width: 8em;
  border-radius: 10px;
  height: 8em;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.5em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

/* main Box */

.mainBox {
  display: flex;

  flex-direction: column;
  width: 50%;
  background-color: white;
  border-radius: 8px;
  padding: 0rem 1rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.title {
  margin-top: 2rem;

  width: 50%;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.inputTitle {
  font-size: 13px;
  color: var(--text-main);
  font-family: "Editorial";
  font-weight: 500;
  margin-bottom: 0rem;
}

.inputValue {
  white-space: nowrap;
  font-size: 13px;
  color: var(--text-body);
  margin-bottom: 0rem;
}

.error123 {
  font-size: 13px;
  color: red;
  margin-bottom: 0rem;
}

.inputValueTitle {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 13px;
  color: var(--text-main);
  margin-left: 0.5rem;
  font-weight: 500;
}
.inputValue1 {
  font-size: 13px;
  color: var(--text-body);
  width: 70%;
  margin-bottom: 0rem;
}

.rowFile {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--primary-border);
  border-radius: 8px;
  padding: 1rem;
}

.containerGrey {
  display: flex;
  flex-direction: column;
  padding: 10px;
  row-gap: 0.5rem;
  width: 100%;
  background-color: var(--primary-bg);
  border-radius: 5px;
  border: 1px solid var(--primary-border);
}

.contentGroup {
  display: flex;
  flex-direction: column;
  padding: 0rem;
  padding-left: 15px;
  row-gap: 0.3rem;
  width: 100%;
  margin-bottom: 0rem;
}

.col2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.smallCsvIcon {
  height: 25px;
}

.checkIcon {
  height: 25px;
  width: 25px;
  color: var(--primary-purple);
  margin-left: 10px;
}

.deleteIcon {
  cursor: pointer;
  height: 25px;
  width: 25px;
  color: var(--primary-red-400);
  border: 1px dashed var(--primary-red-400);
  border-radius: 100%;
  padding: 3px;
  margin-left: auto;
}
.editButton {
  display: flex;
  position: relative;
  height: fit-content;

  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 12px;
  background-color: var(--text-main);
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  color: white;
  padding: 7px 10px;
  font-family: "Editorial";
  width: fit-content;
  transition: 0.2s ease-in-out;
}

.editButton:hover {
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.2s ease-in-out;
}

.selectBox {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.round {
  min-height: 25px;
  min-width: 25px;
  border: 1px solid var(--primary-border);
  border-radius: 100%;
}

.roundActive {
  min-height: 25px;
  min-width: 25px;
  border: 1px solid var(--primary-border);
  border-radius: 100%;
  background-color: var(--primary-purple);
}
.separator {
  border-bottom: 1px solid var(--primary-border);
}

.row {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  row-gap: 0.5rem;
}

.csvIcon {
  height: 80px;
}

.guide {
  text-align: center;
  font-size: 13px;
}

.responseBox {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.nextButton {
  display: flex;
  justify-content: center;
  border: 1px solid var(--primary-border);
  background-color: var(--primary-purple);
  color: white;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  height: 36px;
  align-items: center;
}

.nextButton:hover {
  background-color: var(--primary-green-500);
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 1rem;
}

.previousButton {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: var(--primary-bg-light);
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  height: 36px;
  margin-right: 0.5rem;
}

.previousButton:hover {
  background-color: var(--primary-bg);
}

.topSection {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.selections {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
}

@media (max-width: 600px) {
  .main {
    height: 100%;
  }

  .selectRow {
    justify-content: center;
    margin-top: 1rem;
    flex-direction: column;
  }

  .selectCsv {
    margin-bottom: 1rem;
  }
  .leftSection {
    width: 100%;
  }

  .tool {
    width: 100%;
  }

  .toolActive {
    width: 100%;
  }

  .rightSection {
    width: 100%;
    min-height: calc(100vh - 50px);
    border-radius: 0;
  }

  .navButtonBack {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    left: 0;
    right: 0;
    display: inline;
    margin-top: 5rem;
  }
  .icon {
    color: black;
    height: 20px;
    width: 20px;
  }
}

@media (min-width: 800px) {
  .mobile {
    display: none;
  }
}
