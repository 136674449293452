.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 60%;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: var(--text-main);
  margin-bottom: 0;
}

.subtitle {
  font-size: 16px;
  color: var(--text-body);
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .header {
    max-width: 100%;
  }
}
