.modalContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  /* dont let it grow longer */
}

.imageContainer {
  width: 55%;
  overflow: hidden;
  position: relative;
  background-color: var(--text-main);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-right: 1px solid var(--primary-border);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.post {
  display: flex;
  flex-direction: column;
  width: 45%;
  /* gap: 1rem; */
}

.postMeta {
  margin: 0;
}

.logoSection {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.logoWrapper {
  background-color: var(--primary-purple);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--primary-border);
}

.logoMark {
  object-fit: contain;
}

.logo {
  object-fit: contain;
  height: 15px;
}

.closeButton {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-bg-light);
  color: var(--text-main);

  &:hover {
    background-color: var(--primary-bg);
  }
}

.overFlow {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  padding: 1rem 0rem;
  max-height: calc(100% - 130px);
}

.postDetails {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 0 1rem;
}

.postTitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}

.postDescription {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 150%;
  color: var(--text-body);
}

.listItem {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--text-body);
}

.tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 1rem;
}

.muteText {
  font-size: 12px;
  color: var(--text-mute);
}

.tag {
  background-color: var(--primary-bg);
  color: var(--text-body);
  font-size: 12px;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
}

.productDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--primary-bg-light);
  padding: 1rem;
  border-radius: 15px;
}
.grid {
  display: grid;
  /* 3 items per row */
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.product {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.productImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  position: relative;
  background-color: var(--primary-bg-dark);
  border-radius: 15px;
  aspect-ratio: 1/1 !important;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: 1px solid var(--primary-border);

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
}

.productImageWrapper:hover .productImage {
  transform: scale(1.03);
  opacity: 0.8;
}

.productImage {
  width: 100%;
  height: 100%;
  transition: all 0.1s ease-in-out;

  object-fit: contain;
}

.boxTitle {
  opacity: 0.7;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.prices {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.col {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  padding: 1rem;
  border-top: 1px solid var(--primary-border);
}

.productName {
  font-weight: 400;
  font-size: 12px;
  color: var(--text-body);
  margin-bottom: 0;
}

.price {
  font-weight: 500;
  font-size: 16px;
  color: var(--text-main);
  margin-bottom: 0;
}

.retailPrice {
  font-size: 14px;
  color: var(--text-mute);
  text-decoration: line-through;
  margin-bottom: 0;
}
.brand {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--text-main);
  opacity: 0.8;
  font-weight: 500;
}

.soldBy {
  font-size: 10px;
  color: var(--text-mute);
  margin-bottom: 0;
}

.absoluteContainer {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.badgeLight {
  font-size: 16px;
  border: 1px solid var(--primary-border);
  border-radius: 30px;
  text-decoration: none;
  color: var(--text-main);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: var(--primary-bg);
  }
}

.colored {
  color: var(--primary-purple);
  border: 1px solid var(--primary-purple);
  background-color: var(--primary-pink);

  &:hover {
    background-color: var(--primary-pink);
    opacity: 0.8;
  }
}

.badge {
  font-size: 14px;
  background-color: var(--text-main);
  border: 1px solid #2d2e30;
  border-radius: 15px;
  text-decoration: none;
  color: white;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.1);
}

.badgeIcon {
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .modalContent {
    flex-direction: column;
  }

  .post {
    width: 100%;
  }

  .imageContainer {
    width: 100%;
    border-right: none;
    border-bottom-left-radius: 0;
    border-top-right-radius: 15px;
    min-height: 60vh;
    height: 60vh;
  }

  .overFlow {
    max-height: fit-content;
  }

  .footer {
    position: sticky;
    bottom: 0;
    background-color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}
