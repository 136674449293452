.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  margin-top: 1.5rem;
}

.postWrapper {
  max-width: var(--max-width);
  border: 1px solid var(--primary-border);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: fit-content;
}
