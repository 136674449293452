.Button {
  background-color: white;
  color: var(--primary-purple);
  border: 1px solid var(--primary-purple);
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 15px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.Button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

@media (max-width: 600px) {
  .Button {
    padding: 5px 4px;
  }
}
